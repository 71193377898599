import { useEffect, useRef } from "react";
import { graphql, useFragment } from "react-relay";
import {
  HeaderFragment$data,
  HeaderFragment$key,
} from "./__generated__/HeaderFragment.graphql";
import { FormattedMessage } from "react-intl";
import NavItem from "./NavItem";
import LogoutButton from "./LogoutButton";
import EntityProfilePic from "./EntityProfilePic";
import Button from "./Button";
import Logo from "./Logo";
import LogoIcon from "./LogoIcon";
import * as Drawer from "./Drawer";
import { MdMenu } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { MdOutlineArrowOutward } from "react-icons/md";

const HeaderFragment = graphql`
  fragment HeaderFragment on Query {
    viewer @ifAllowed {
      id
      displayName
      username
      canCreateEvent: can(action: CREATE_EVENT)
      ...EntityProfilePicFragment @arguments(thumbnail: true)
    }
    events(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

interface Props {
  query: HeaderFragment$key;
}

const LogoWrapper = () => (
  <>
    <div className="w-32 hidden lg:block">
      <Logo />
    </div>
    <div className="w-6 lg:hidden">
      <LogoIcon />
    </div>
  </>
);

const NavItems = ({
  showEvents,
  vertical = false,
}: {
  showEvents: boolean;
  vertical?: boolean;
}) => (
  <>
    <NavItem to={`/competitions`} vertical={vertical}>
      <FormattedMessage defaultMessage="Competitions" />
    </NavItem>
    {showEvents && (
      <NavItem to={`/events`} vertical={vertical}>
        <FormattedMessage defaultMessage="Events" />
      </NavItem>
    )}
    <NavItem to={`/discussions`} vertical={vertical}>
      <FormattedMessage defaultMessage="Discussions" />
    </NavItem>
    {import.meta.env.VITE_QUANTUM_JOBS && (
      <NavItem
        to={import.meta.env.VITE_QUANTUM_JOBS}
        target="_blank"
        vertical={vertical}
        icon={<MdOutlineArrowOutward />}
      >
        <FormattedMessage defaultMessage="Job Board" />
      </NavItem>
    )}
  </>
);

const UserButtons = ({ viewer }: { viewer: HeaderFragment$data["viewer"] }) => {
  if (viewer) {
    return (
      <>
        <Button kind="text" to={`/${viewer.username}`}>
          <div className="flex flex-row items-center">
            <div className="whitespace-nowrap pr-2 hidden lg:block">
              {viewer.displayName}
            </div>
            <EntityProfilePic entity={viewer} size="8" />
          </div>
        </Button>
        <LogoutButton />
      </>
    );
  }
  return (
    <>
      <Button kind="text" to="/login">
        <FormattedMessage defaultMessage="Login" />
      </Button>
      <Button to="/signup">
        <FormattedMessage defaultMessage="Signup" />
      </Button>
    </>
  );
};

export default function Header({ query }: Props) {
  const data = useFragment(HeaderFragment, query);
  const location = useLocation();
  const closeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (closeRef.current) {
      closeRef.current.click();
    }
  }, [location]);

  const showEvents =
    data.events.edges.length > 0 || data.viewer?.canCreateEvent;

  return (
    <div className="flex flex-row pt-3">
      <Button kind="text" to="/" className="hidden md:block mr-4">
        <LogoWrapper />
      </Button>

      <div className="hidden md:flex flex-row space-x-2 items-center w-full">
        <NavItems showEvents={showEvents} />
      </div>

      <div className="md:hidden w-full">
        <Drawer.Root>
          <Drawer.Trigger asChild>
            <div className="inline-block p-3">
              <MdMenu size={24} />
            </div>
          </Drawer.Trigger>
          <Drawer.Content>
            <div className="flex flex-col pt-4 pb-4">
              <NavItem to={`/`} vertical>
                <FormattedMessage defaultMessage="Home" />
              </NavItem>
              <NavItems showEvents={showEvents} vertical />
            </div>
          </Drawer.Content>
          <Drawer.Close asChild>
            <button ref={closeRef} className="hidden" />
          </Drawer.Close>
        </Drawer.Root>
      </div>

      <div className="flex flex-grow-0 items-center">
        <UserButtons viewer={data.viewer} />
      </div>
    </div>
  );
}
