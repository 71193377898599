/**
 * @generated SignedSource<<70a8d3e871be816af5e8852683212f08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Badge = "BIG_PARIS_2021" | "CHICAGO_2023" | "GRENOBLE_2022" | "MONTREAL_2022" | "PARIS_2021" | "PARIS_2023" | "PARIS_2024_HACKERS" | "PARIS_2024_WINNERS" | "TEST" | "TRIESTE_2023" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type EntityBadgeListFragment$data = {
  readonly badges: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly badge: Badge;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"EntityBadgeFragment">;
      };
    }>;
  };
  readonly id: string;
  readonly username: string;
  readonly viewerCanAward: boolean;
  readonly " $fragmentType": "EntityBadgeListFragment";
};
export type EntityBadgeListFragment$key = {
  readonly " $data"?: EntityBadgeListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EntityBadgeListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "badges"
        ]
      }
    ]
  },
  "name": "EntityBadgeListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": "viewerCanAward",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "AWARD_BADGE"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"AWARD_BADGE\")"
    },
    {
      "alias": "badges",
      "args": null,
      "concreteType": "EntityBadgeConnection",
      "kind": "LinkedField",
      "name": "__EntityBadgeList_entity_badges_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EntityBadgeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EntityBadge",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "badge",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EntityBadgeFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Entity",
  "abstractKey": "__isEntity"
};
})();

(node as any).hash = "8097abf249b7bf219d4df136329c1c16";

export default node;
