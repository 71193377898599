import { graphql } from "react-relay";
import { defineMessage } from "react-intl";
import { Badge as BadgeName } from "./__generated__/badges.graphql";
import { MessageDescriptor } from "react-intl";
import test from "./test.png";
import paris2024_winners from "./paris2024-winners.webp";
import paris2024_hackers from "./paris2024-hackers.webp";
import chicago_2023 from "./chicago2023.png";
import trieste_2023 from "./trieste2023.png";
import paris_2023 from "./paris2023.png";
import grenoble_2022 from "./grenoble2022.jpg";
import montreal_2022 from "./montreal2022.jpg";
import bigparis_2021 from "./bigparis2021.jpg";
import paris_2021 from "./paris2021.jpg";

graphql`
  fragment badges on EntityBadge {
    badge
  }
`;

export interface Badge {
  id: BadgeName;
  name: MessageDescriptor;
  description: MessageDescriptor;
  image: string;
}

function createBadges(...badges: Badge[]): Record<BadgeName, Badge> {
  return badges.reduce<Record<BadgeName, Badge>>(
    (acc, badge) => {
      const duplicated = acc[badge.id];
      if (duplicated) {
        throw new Error(
          `Duplicated badge id "${badge.id}": ${JSON.stringify(duplicated)} ${JSON.stringify(badge)}`,
        );
      }
      return { ...acc, [badge.id]: badge };
    },
    {} as Record<BadgeName, Badge>,
  );
}

export const badges: Record<BadgeName, Badge> = createBadges(
  {
    id: "TEST",
    name: defineMessage({
      id: "test-name",
      defaultMessage: "Quantum Badge",
    }),
    description: defineMessage({
      id: "test-description",
      defaultMessage:
        "You did some awesome work, this really deserves a badge!",
    }),
    image: test,
  },
  {
    id: "PARIS_2024_WINNERS",
    image: paris2024_winners,
    name: defineMessage({
      id: "paris2024-winners-badge",
      defaultMessage: "Big Quantum Hackathon Paris 2024 Winner",
    }),
    description: defineMessage({
      id: "paris2024-winners-badge-description",
      defaultMessage:
        "Won the Big Quantum Hackathon Sports Edition, held in Paris from May 25 to 28, 2024.",
    }),
  },
  {
    id: "PARIS_2024_HACKERS",
    image: paris2024_hackers,
    name: defineMessage({
      id: "paris2024-hackers-badge",
      defaultMessage: "Big Quantum Hackathon Paris 2024 Participant",
    }),
    description: defineMessage({
      id: "paris2024-hackers-badge-description",
      defaultMessage:
        "Participated in the Big Quantum Hackathon Sports Edition, held in Paris from May 25 to 28, 2024.",
    }),
  },
  {
    id: "CHICAGO_2023",
    image: chicago_2023,
    name: defineMessage({
      id: "chicago2023-badge",
      defaultMessage: "BIG Quantum Hackathon Chicago 2023 Participant",
    }),
    description: defineMessage({
      id: "chicago2023-badge-description",
      defaultMessage:
        "Participated in the BIG Quantum Hackathon by the Chicago Quantum Exchange & QuantX, held in Chicago (Sept, 2023).",
    }),
  },
  {
    id: "TRIESTE_2023",
    image: trieste_2023,
    name: defineMessage({
      id: "trieste2023-badge",
      defaultMessage: "ICTP - Quantinuum Quantum Hackathon 2023 Participant",
    }),
    description: defineMessage({
      id: "trieste2023-badge-description",
      defaultMessage:
        "Participated in the ICTP - Quantinuum Quantum Hackathon, held in Trieste (April, 2023).",
    }),
  },
  {
    id: "PARIS_2023",
    image: paris_2023,
    name: defineMessage({
      id: "paris2023-badge",
      defaultMessage: "BIG QC-AI-HPC Hackathon 2023 Participant",
    }),
    description: defineMessage({
      id: "paris2023-badge-description",
      defaultMessage:
        "Participated in the BIG QC-AI-HPC Hackathon by QuantX, held in Paris (March, 2023).",
    }),
  },
  {
    id: "GRENOBLE_2022",
    image: grenoble_2022,
    name: defineMessage({
      id: "grenoble2022-badge",
      defaultMessage: "Quantum Hackathon 2022 Participant",
    }),
    description: defineMessage({
      id: "grenoble2022-badge-description",
      defaultMessage:
        "Participated in the Quantum Hackathon by QuantX, held in Grenoble (October, 2022).",
    }),
  },
  {
    id: "MONTREAL_2022",
    image: montreal_2022,
    name: defineMessage({
      id: "montreal2022-badge",
      defaultMessage: "Quantum Hackathon 2022 Participant",
    }),
    description: defineMessage({
      id: "montreal2022-badge-description",
      defaultMessage:
        "Participated in the Quantum Hackathon by Québec Quantique & QuantX, held in Montreal (June, 2022).",
    }),
  },
  {
    id: "BIG_PARIS_2021",
    image: bigparis_2021,
    name: defineMessage({
      id: "bigparis2021-badge",
      defaultMessage: "BIG Quantum Hackathon 2021 Participant",
    }),
    description: defineMessage({
      id: "bigparis2021-badge-description",
      defaultMessage:
        "Participated in the BIG Quantum Hackathon by QuantX, held in Paris (October, 2021).",
    }),
  },
  {
    id: "PARIS_2021",
    image: paris_2021,
    name: defineMessage({
      id: "paris2021-badge",
      defaultMessage: "Quantum Hackathon 2021 Participant",
    }),
    description: defineMessage({
      id: "paris2021-badge-description",
      defaultMessage:
        "Participated in the Quantum Hackathon by QuantX, held in Paris (March, 2021).",
    }),
  },
);
