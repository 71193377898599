import { FormattedMessage, MessageDescriptor } from "react-intl";
import { useAuth } from "../utils/auth";
import Button from "./Button";
import { cn } from "../utils/tailwind";

export interface SignupCalloutProps {
  className?: string | undefined;
  title: MessageDescriptor;
  body: MessageDescriptor;
}

export default function SignupCallout({
  className,
  title,
  body,
}: SignupCalloutProps) {
  const { userId } = useAuth();
  if (userId) {
    return null;
  }

  return (
    <div
      className={cn(
        className,
        "flex flex-col gap-2 bg-gray-50 p-3 rounded-lg border",
      )}
    >
      <h4 className="font-bold text-lg">
        <FormattedMessage {...title} />
      </h4>
      <p>
        <FormattedMessage {...body} />
      </p>
      <div className="flex flex-row justify-end flex-wrap mt-2 gap-2">
        <Button to="/login" kind="text">
          <FormattedMessage defaultMessage="Login" />
        </Button>
        <Button to="/signup">
          <FormattedMessage defaultMessage="Sign up" />
        </Button>
      </div>
    </div>
  );
}
