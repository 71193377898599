import { useIntl } from "react-intl";
import Markdown, { MarkdownProps } from "./Markdown";
import { cn } from "../utils/tailwind";

export default function UserMarkdown({ components, ...props }: MarkdownProps) {
  const intl = useIntl();
  return (
    <Markdown
      {...props}
      components={{
        img({ className, ...props }) {
          return (
            <a
              href={props.src}
              target="_blank"
              className="contents"
              title={intl.formatMessage({
                defaultMessage: "Click to open image",
              })}
            >
              <img
                {...props}
                className={cn(
                  className,
                  "max-h-96 rounded border border-slate-200 hover:border-slate-300 p-2 my-2",
                )}
              />
            </a>
          );
        },
        ...components,
      }}
    />
  );
}
